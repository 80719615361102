const FIRST_NAME_HEADER = 'First Name';
const MIDDLE_NAME_HEADER = 'Middle Name';
const LAST_NAME_HEADER = 'Last Name';
const DOB_HEADER = 'DOB';
const GENDER_HEADER = 'Gender';
const ADDRESS_1_HEADER = 'Address Line 1';
const ADDRESS_2_HEADER = 'Address Line 2';
const CITY_HEADER = 'City';
const STATE_HEADER = 'State';
const ZIP_HEADER = 'Zip Code';
const PHONE_HEADER = 'Phone';
const SSN_HEADER = 'SSN';
const INSURANCE_HEADER = 'Insurance';

const CORRECT_HEADERS = [
	FIRST_NAME_HEADER,
	MIDDLE_NAME_HEADER,
	LAST_NAME_HEADER,
	DOB_HEADER,
	GENDER_HEADER,
	ADDRESS_1_HEADER,
	ADDRESS_2_HEADER,
	CITY_HEADER,
	STATE_HEADER,
	ZIP_HEADER,
	PHONE_HEADER,
	SSN_HEADER,
	INSURANCE_HEADER,
];

const CUSTOM_HEADERS_MAP = {
	[FIRST_NAME_HEADER]: 'firstName',
	[MIDDLE_NAME_HEADER]: 'middleName',
	[LAST_NAME_HEADER]: 'lastName',
	[DOB_HEADER]: 'dob',
	[GENDER_HEADER]: 'gender',
	[ADDRESS_1_HEADER]: 'addressLine1',
	[ADDRESS_2_HEADER]: 'addressLine2',
	[CITY_HEADER]: 'city',
	[STATE_HEADER]: 'state',
	[ZIP_HEADER]: 'zip',
	[PHONE_HEADER]: 'phone',
	[SSN_HEADER]: 'ssn',
	[INSURANCE_HEADER]: 'insurance',
};

module.exports = {
	FIRST_NAME_HEADER,
	MIDDLE_NAME_HEADER,
	LAST_NAME_HEADER,
	DOB_HEADER,
	GENDER_HEADER,
	ADDRESS_1_HEADER,
	ADDRESS_2_HEADER,
	CITY_HEADER,
	STATE_HEADER,
	ZIP_HEADER,
	PHONE_HEADER,
	SSN_HEADER,
	INSURANCE_HEADER,
	CORRECT_HEADERS,
	CUSTOM_HEADERS_MAP,
};
